.unscramble-sentence-words-activity {
    margin-bottom: 50px;
}

.unscramble-sentence-words-activity__activity-content {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
}

.unscramble-sentence-words-activity__expression {
    text-align: center;
    font-size: 5rem;
}

.unscramble-sentence-words-activity__answer-wrapper {
    margin-top: 40px;
}

.unscramble-sentence-words-activity__answer-letters-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.unscramble-sentence-words-activity__answer-letter {
    font-size: 2.2rem;
    /* border: 2px solid var(--third-level-color);

    background-color: var(--third-level-color); */
    color: #fff;
    min-width: 50px;
    height: 50px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 3px;
}

.unscramble-sentence-words-activity__answer-letter:hover {
    cursor: pointer;
}

.unscramble-sentence-words-activity__answer-underline {
    width: 100%;
    height: 4px;
    margin-top: 7px;
    background-color: var(--gray-color);
}

.unscramble-sentence-words-activity__letters-wrapper {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.unscramble-sentence-words-activity__letter {
    font-size: 2.4rem;
    border: 2px solid var(--third-level-color);
    min-width: 50px;
    height: 50px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 3px;
    cursor: pointer;
}

.unscramble-sentence-words-activity__letter--selected {
    border-color: var(--gray-color) !important;
    background-color: var(--gray-color);
    color: var(--gray-color);
    pointer-events: none;
}

.unscramble-sentence-words-activity__speaker-box  {
    background-color: transparent;
    border-radius: 5px;
    font-size: 4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
}

.unscramble-sentence-words-activity__speaker-button  {
    background-color: transparent;
    border: none;
    border-radius: 5px;
    font-size: 2.5rem;
    cursor: pointer;
    margin: 0 5px;
}


@media (max-width: 600px) {
    .unscramble-sentence-words-activity__answer-letter , .unscramble-sentence-words-activity__letter  {
       font-size: 2rem;
        height: 40px;
    }
}